import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "../../../../components/general/Modal";
import FormComponent from "../../../../components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
  const { onChange, data, modal, toggle, errors , categories, catalogs, isFromEdit} = props;

  return (
    <>
      {!isFromEdit && (<Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>)}
      <ModalComponent title="Create New Category" toggle={toggle} isOpen={modal}>
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="text"
            name="name_en"
            label="English Name"
            value={data.name_en}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError") ) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

           <FormComponent
            type="text"
            name="name_ar"
            label="Arabic Name"
            value={data.name_ar}
            updateState={onChange}
            error={_.get(errors, "nameArError")}
          />
          {!_.isEmpty(_.get(errors, "nameArError") ) && (
            <Alert color="danger"> {_.get(errors, "nameArError")} </Alert>
          )}

           <FormComponent
            type="text"
            name="external_id"
            label="External Id"
            value={data.external_id}
            updateState={onChange}
            error={_.get(errors, "externalError")}
          />
          {!_.isEmpty(_.get(errors, "externalError") ) && (
            <Alert color="danger"> {_.get(errors, "externalError")} </Alert>
          )}

            <FormComponent
              type="groupCheckbox"
              options={_.map(categories, category => ({ label: _.get(category, 'name_en'), value: _.get(category, '_id') }))}
              name="parent"
              label="Category Parent"
              value={data.parent}
              valueKey="value"
              labelKey="label"
              updateState={onChange}
            />

          <FormComponent
            type="groupCheckbox"
            options={_.map(categories, category => ({ label: _.get(category, 'name_en'), value: _.get(category, '_id') }))}
            name="childs"
            label="Category Childs"
            value={data.childs}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <FormComponent
            type="groupCheckbox"
            options={_.map(catalogs, catalog => ({ label: _.get(catalog, 'name_en'), value: _.get(catalog, '_id') }))}
            name="catalogs"
            label="Catalogs"
            value={data.catalogs}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <ModalFooter>
            <Button
              //type="submit"
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
